.quote {
  width: 40px !important;
}
.customCard {
  height: fit-content !important;
}

.mission-vision {
  border: 0.5px solid #7b1ffe;
  width: fit-content;
  border-radius: 12px;
  padding: 8px;
}

.mission-vision img {
  max-width: 40px !important;
}

.get-certified p {
  text-align: justify;
}

.certified-group:hover {
  border: 0.5px solid #7b1ffe;
}

.text-violet {
  color: #7b1ffe;
}

.learn-anything p {
  text-align: justify;
}

.award-list span {
  width: 20px;
}

.textdetails {
  color: #392c7d;
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
}

.qoutation-paragraph {
  position: relative;
  padding: 0 20px;
  font-style: italic;
}

.qoutation-paragraph::before {
  content: '"';
  font-size: 2em;
  position: absolute;
  top: -10px;
}

.qoutation-paragraph::after {
  content: '"';
  font-size: 2em;
  position: absolute;
  top: 19px;
  right: 271px;
}

.qoutation-paragraph::before {
  left: 0;
}
